import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled, { keyframes, createGlobalStyle } from 'styled-components';

const traits = [
  'Nashville', 'Bioengineering', 'Hill', 'Funny', 'Mendhi',
  'Southern', 'Silly', 'Incredible', 'Finance', 'Sweat',
  'Tryhard', 'Charming', 'Thoughtful', 'Creative', 'Loyal',
  'Adventurous', 'Smart', 'Generous', 'Passionate', 'Spontaneous',
  'Confident', 'Curious', 'Kind-hearted'
];

// Helper function to get a random color from the rainbow
const getRandomColor = () => {
  const colors = ['#FF0000', '#FF7F00', '#FFFF00', '#00FF00', '#0000FF', '#4B0082', '#9400D3'];
  return colors[Math.floor(Math.random() * colors.length)];
};

// Global styles to prevent horizontal scrolling only
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Only prevent horizontal scrolling */
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const BackgroundContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
`;

const TraitsList = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: clamp(1rem, 3vw, 2rem);
  display: grid;
  grid-template-rows: repeat(15, 1fr);
  color: gray;
  font-family: 'JetBrains Mono', 'Courier New', monospace;
  text-align: center;
  overflow: hidden;

  /* Responsive adjustments */
  @media (max-width: 600px) {
    grid-template-rows: repeat(10, 1fr); /* Fewer rows on smaller screens */
    padding: 1rem;
  }
`;

const TraitRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  gap: clamp(0.5rem, 1vw, 2rem);

  /* Ensure that the gap reduces on smaller screens */
  @media (max-width: 600px) {
    gap: 0.25rem;
  }
`;

const TraitName = styled.span`
  color: ${({ isHighlighted }) => (isHighlighted ? getRandomColor() : 'black')};
  transition: color 0.5s ease;
  font-size: clamp(0.5rem, 1.5vw, 1.5rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: min-content;

  /* Adjust font size on smaller screens */
  @media (max-width: 600px) {
    font-size: clamp(1.5rem, 1vw, 1rem); /* Smaller font size */
  }
`;

const WhiteBox = styled.div`
  position: relative;
  color: rgba(255, 255, 255, 0.7);
  padding: clamp(1rem, 3vw, 2rem);
  border-radius: 10px;
  font-size: clamp(1.5rem, 4vw, 3rem);
  font-weight: 700;
  opacity: 0;
  animation: ${fadeIn} 4s forwards;
  z-index: 10;
  text-align: center;
  max-width: 90vw;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  /* Responsive adjustments */
  @media (max-width: 600px) {
    padding: 1rem; /* Smaller padding */
    font-size: clamp(2rem, 3vw, 2.5rem); /* Slightly smaller font size */
  }
`;

const BackgroundEffect = () => {
  const { girlName } = useParams();
  const [highlightedTraits, setHighlightedTraits] = useState([]);

  // Create grid of traits
  const rows = 15;
  const cols = 15;
  const shuffledTraits = Array(rows * cols).fill().map(() =>
    traits[Math.floor(Math.random() * traits.length)]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const highlighted = Array(80).fill().map(() =>
        Math.floor(Math.random() * (rows * cols))
      );
      setHighlightedTraits(highlighted);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const displayName = girlName ? girlName.charAt(0).toUpperCase() + girlName.slice(1) : 'LOML';

  return (
    <>
      <GlobalStyle />
      <BackgroundContainer>
        <TraitsList>
          {Array.from({ length: rows }).map((_, rowIndex) => (
            <TraitRow key={rowIndex}>
              {shuffledTraits
                .slice(rowIndex * cols, (rowIndex + 1) * cols)
                .map((trait, index) => (
                  <TraitName
                    key={index}
                    isHighlighted={highlightedTraits.includes(rowIndex * cols + index)}
                  >
                    {trait}
                  </TraitName>
                ))}
            </TraitRow>
          ))}
        </TraitsList>
        <WhiteBox>Hi {displayName}</WhiteBox>
      </BackgroundContainer>
    </>
  );
};

export default BackgroundEffect;
